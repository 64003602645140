<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Berkas : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- email -->
          <b-form-group label="Kategori" label-for="Email">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search Kategori Berkas"
                  v-model="filter.document_category"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama_lengkap -->
          <b-form-group label="Judul" label-for="Nama Lengkap">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search judul berkas"
                  v-model="filter.document_title"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="modalTambahBerkas()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Berkas</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalEditBerkas(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteBerkas(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal
      id="modal-add-berkas"
      title="Tambah Berkas"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeSubmitBerkas">
        <b-form @submit.prevent>
          <b-form-group label="kategori" label-for="kategori ">
            <validation-provider
              #default="{ errors }"
              name="kategori"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="kategori"
                  v-model="document_category"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option_category_berkas"
                  placeholder="Pilih Kategori Berkas..."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="judul" label-for="judul ">
            <validation-provider
              #default="{ errors }"
              name="judul"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="judul"
                  v-model="document_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="masukan judul berkas"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="file" label-for="file">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-form-file
                id="file"
                multiple
                v-model="document_file"
                :file-name-formatter="formatNames"
                :state="errors.length > 0 ? false : null"
                @change="handleImg"
                accept="image/*"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBerkas"
          >
            Tambah Berkas
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-update-berkas"
      title="Edit Berkas"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeUpdateBerkas">
        <b-form @submit.prevent>
          <table style="width: 100%">
            <tr>
              <td>File</td>
              <td>:</td>
              <td>
                <a :href="updateBerkas.document_file" target="_blank">
                  {{ "lihat berkas" }}</a
                >
              </td>
            </tr>
          </table>

          <br />
          <b-form-input
            id="id_berkas"
            v-model="updateBerkas.id"
            placeholder="Id Berkas"
            hidden
          />
          <b-form-group label="kategori" label-for="kategori ">
            <validation-provider
              #default="{ errors }"
              name="kategori"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="kategori"
                  v-model="updateBerkas.document_category"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option_category_berkas"
                  placeholder="category berkas.."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="judul" label-for="judul ">
            <validation-provider
              #default="{ errors }"
              name="judul"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="judul"
                  v-model="updateBerkas.document_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Judul Berkas"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="file" label-for="file">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file"
                  multiple
                  v-model="updateBerkas.document_file"
                  :file-name-formatter="formatNames"
                  :state="errors.length > 0 ? false : null"
                  @change="handleImg"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBerkasUpdate"
          >
            Update Berkas
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "document_category", label: "kategori" }],
        [{ key: "document_title", label: "title" }],
        [{ key: "document_file", label: "file" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      option_category_berkas: [
        "Buku Dan Panduan",
        "Surat Keputusan",
        "Surat Edaran",
        "Peraturan Bidang Kesehatan",
      ],

      filter: {
        document_category: "",
        document_title: "",
      },

      search: null,
      updateBerkas: {},
      base64Data: "",
      option: ["video", "image"],
    };
  },
  methods: {
    validateBerkas() {
      this.$refs.validateBeforeSubmitBerkas.validate().then((success) => {
        if (success) {
          this.submitBerkas();
        }
      });
    },
    validateBerkasUpdate() {
      this.$refs.validateBeforeUpdateBerkas.validate().then((success) => {
        if (success) {
          this.editDataBerkas();
        }
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getBerkas();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalTambahBerkas() {
      this.$bvModal.show("modal-add-berkas");
    },

    modalEditBerkas(item) {
      this.updateBerkas = item;
      this.$bvModal.show("modal-update-berkas");
    },

    async getBerkas() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          // add filter
          document_category: this.filter.document_category,
          document_title: this.filter.document_title,
        };
        const { data } = await API.berkas.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data berkas");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async submitBerkas() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        // data img
        const dataImg = document.querySelector("input[type=file]").files[0];
        let reader = new FileReader();
        //load data url selanjutnya di lakukan proses convert to base 64
        reader.onloadend = async () => {
          let dataBerkas = {
            document_category: this.document_category,
            document_title: this.document_title,
            document_file: reader.result,
          };
          await API.berkas.add(dataBerkas);
          this.handleMsgSuccess("Berhasil tambah berkas");
          this.refreshPage();
        };
        reader.readAsDataURL(dataImg);
      } catch (error) {
        this.handleMsgErrors(error, "form yang anda isi salah");
      } finally {
        this.$bvModal.hide("modal-add-berkas");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async editDataBerkas() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        // data img
        const dataImg = document.querySelector("input[type=file]").files[0];

        let reader = new FileReader();

        reader.onloadend = async () => {
          let ids = this.updateBerkas.id; // get id for update data gallery

          let body = {
            document_category: this.updateBerkas.document_category,
            document_title: this.updateBerkas.document_title,
            document_file: reader.result,
          };
          await API.berkas.update(ids, body);
          this.handleMsgSuccess("Berhasil Update berkas");
          this.refreshPage();
        };
        reader.readAsDataURL(dataImg);
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update berkas");
      } finally {
        this.$bvModal.hide("modal-update-berkas");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteBerkas(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let deleteGaleri = ids.id;

        const { data } = await API.berkas.delete(deleteGaleri);
        this.handleMsgSuccess("Berhasil Delete berkas");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal delete berkas");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getBerkas();
  },
  watch: {
    currentPage() {
      this.getBerkas();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
